<template>
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col>
        <b-container class="text-center">
          <h1>Loading data</h1>
          <h3>... loaded: {{rows.length}} ...</h3>
          <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
        </b-container>
      </b-col>
    </b-row>
    <filter-interface
      v-if="!loading && permissions.read"
      :model="model"
      :nav="nav"
      :pageName="pageName"
      :rows="rows"
      :searchProperties="searchProperties"
      :staticDataProperties="staticDataProperties"
      :staticData="staticData"
      :loading="loading"
      :permissions="permissions"
      :sortDirectionProp="sortDirection"
      :sortPropertyProp="sortProperty"
      :sortPropertyOptionsProp="sortPropertyOptions"
    />
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import FilterInterface from '@/components/FilterInterface.vue'

export default {
  name: 'Experts',
  components: {
    FilterInterface
  },
  computed: {
    rows: {
      get () {
        return this.$store.state.experts
      },
      set (payload) {
        this.$store.commit('setExperts', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permissions.read = true
    this.permissions.add = ac.can(this.user.acgroups).createAny('impactexpert').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    return {
      loading: true,
      model: 'impactexpert',
      nav: [
        { name: 'Experts', text: 'Experts' }
        // { name: 'SanctionsDataStrats', text: 'Data stratification' }
      ],
      pageName: 'Experts',
      permissions: {
        add: false,
        read: false
      },
      searchProperties: ['forename', 'surname', 'title', 'country', 'email'],
      staticDataProperties: [
        { name: 'Country', property: 'country', direct: 'country' }
      ],
      sortDirection: 'asc',
      sortProperty: 'surname',
      sortPropertyOptions: [
        { value: 'forename', text: 'forename' },
        { value: 'surname', text: 'surname' },
        { value: 'country', text: 'country' },
        { value: 'email', text: 'email' },
        { value: 'title', text: 'title' }
      ],
      staticData: null
    }
  },
  methods: {
    load: async function () {
      try {
        this.loading = true
        await this.loadRows()
        const staticData = {
          country: []
        }
        _.each(this.rows, row => {
          staticData.country.push({ id: row.country, name: row.country })
        })
        staticData.country = _.uniqBy(staticData.country, 'id')
        staticData.country = _.orderBy(staticData.country, ['id'])
        this.staticData = staticData
        console.log('static data')
        console.log(this.staticData)
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    loadRows: async function () {
      this.loading = true
      this.rows = []
      try {
        const limit = 100
        let offset = 0
        let response = []
        let page = 0
        let data = []
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/experts/limit/${limit}/offset/${offset}`)
          data = _.concat(this.rows, response)
          this.rows = data
          page++
          await this.$nextTick()
        } while (response.length === limit)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
